<template>
  <div class="mt-14 grid place-items-center gap-14 rounded-xl bg-ivory-dark p-7 dark:bg-black md:grid-cols-2 md:p-14">
    <h2 class="text-4xl font-semibold md:text-5xl">
      Hey, I’m Filip! 👋
    </h2>
    <div class="text-2xl">
      I am a tester at heart, with huge passion for web testing in and teaching. I write blogs, conduct workshops and speak on testing all around the world. Glad to meet you!
      <div class="pt-7">
        <NuxtLink to="/about" class="prettyLink inline-block font-bold">
          Learn more <IconArrowForward class="inline transition-all ease-in-out" />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
